import advanced_ai from "../resources/advanced_ai.jpg";
import complete_automation from "../resources/complete_automation.jpg";
import seamless_connectivity from "../resources/seamless_connectivity.jpg";

const features = [
  {
    title: "AI Technology",
    href: "#",
    category: { name: "Article", href: "#" },
    description:
      "Malprob's AI technology is built on millions of unified parameters, enabling the detection of even the most obscure malware threats. Our AI is always innovating, ensuring our clients are protected from emerging threats.",
    imageUrl: advanced_ai,
  },
  {
    title: "API Integration",
    description:
      "Our platform is designed to connect to other security tools via API, allowing for easy integration and enhanced detection capabilities.",
    imageUrl: seamless_connectivity,
  },
  {
    title: "Automation",
    href: "#",
    category: { name: "Video", href: "#" },
    description:
      "Our service is 100% automated, meaning no human intervention is necessary. With the fastest analysis times on the market, you can enjoy top-of-the-line cybersecurity without any compromises.",
    imageUrl: complete_automation,
  },
];

export default function Cards() {
  return (
    <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Our Features
          </h2>
          <p className="w-full text-center px-20 text-gray-500 pt-3">
            Powered by{" "}
            <a
              className="hover:opacity-100 text-nucleon-700"
              href="https://nucleon-security.com"
              target="_blank"
            >
              Nucleon Security
            </a>
            .
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">
          {features.map((feature) => (
            <div key={feature.title} className="flex flex-col overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-56 w-full object-cover"
                  src={feature.imageUrl}
                  alt={feature.title}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between text-center">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900">
                    {feature.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
