import { useState } from "react";

const ReportCategory = ({ category, report }) => {
  const items = report["nested"].filter((field) => field.category === category);
  const [visibleCount, setVisibleCount] = useState(10);

  const handleExpand = () => {
    setVisibleCount((prev) => Math.min(prev + 10, items.length));
  };

  const handleCollapse = () => {
    setVisibleCount((prev) => Math.max(prev - 10, 10));
  };

  return (
    <div className="border mb-4 p-4 sm:grid sm:grid-cols-6 sm:gap-4 bg-white w-full max-w-7xl">
      <dt className="text-sm font-semibold text-gray-700">{category}</dt>
      <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
        <ul>
          {items.slice(0, visibleCount).map((field, key) => (
            <li key={key} className="py-1 flex items-center gap-2">
              <span className="text-gray-700">●</span>
              <span className="hover:underline cursor-pointer">{field.name}</span>
            </li>
          ))}
        </ul>

        <div className="mt-3 flex gap-4">
          {visibleCount < items.length && (
            <button
              onClick={handleExpand}
              className="text-sm font-medium text-blue-500 hover:text-blue-700"
            >
              Show More ▼
            </button>
          )}
          {visibleCount > 10 && (
            <button
              onClick={handleCollapse}
              className="text-sm font-medium text-red-500 hover:text-red-700"
            >
              Show Less ▲
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportCategory;
