import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import notFound404 from "../resources/notFound404.svg";
import axios from "axios";
import Error from "../components/Error";
import Warning from "../components/Warning";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Background from "../components/Background";
import fileDownload from "js-file-download";
import PopUp from "../components/PopUp";
import { MdLoop, MdFileDownload } from "react-icons/md";
import ReportCategory from "../components/ReportCategory";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function formatBytes(bytes) {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
}

const date = (date) => {
  let day = date;
  return `${DAYS[day.getDay()]} ${day.getDate()} ${
    MONTHS[day.getMonth()]
  } ${day.getFullYear()} - ${
    day.getHours() < 10 ? "0" + day.getHours() : day.getHours()
  }:${day.getMinutes() < 10 ? "0" + day.getMinutes() : day.getMinutes()}`;
};

function color(num) {
  if (num < 30) return "#22c55e";
  if (num > 70) return "#ef4444";
  return "#f59e0b";
}

function percentage(num) {
  if ((+num * 100).toFixed(0) < 100) return (+num * 100).toFixed(0);
  return 100;
}

function Report() {
  const { hashcode } = useParams();
  const { token, user, updateToken } = useContext(AuthContext);
  const [report, setReport] = useState();
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [upgrade, setUpgrade] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [code, setCode] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();

  const fileSearch = (accessToken) => {
    if (![64, 40, 32].includes(hashcode.length)) {
      setError(true);
      setLoading(false);
      setWarning(false);
      setNotFound(false);
    } else {
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .get(`${baseURL}/search/${hashcode}`, {
          headers: headers,
        })
        .then((response) => {
          if (response.status === 200) {
            setReport(response.data);
            setNotFound(false);
            setWarning(false);
            setLoading(false);
            setError(false);
          } else if (response.status === 204) {
            setWarning(true);
            setError(false);
            setReport(undefined);
            setNotFound(false);
            setLoading(false);
          } else if (response.status === 202) {
            setReport(response.data);
            setLoading(true);
            setWarning(false);
            setError(false);
            setNotFound(false);
            setCode(hashcode);
          }
        })
        .catch((response) => {
          console.log(response.data);

          if (
            response.response.status === 404 ||
            response.response.status === 400
          ) {
            setNotFound(true);
            setReport(undefined);
            setWarning(false);
            setError(false);
            setLoading(false);
          } else if (response.response.status === 401) {
            updateToken((success, newToken) => {
              if (success) {
                fileSearch(newToken);
              } else {
                navigate("/signin");
              }
            });
          }
        });
    }
  };

  useEffect(() => {
    fileSearch(hashcode);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [state, hashcode]);

  const rescan = (hashcode, accessToken) => {
    if (!accessToken) {
      setUpgrade(false);
      setPopUp(true);
      setError(false);
      setWarning(false);
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access}`,
    };
    axios
      .post(
        `${baseURL}/rescan/`,
        { hashcode: hashcode ? hashcode : code },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(true);
          setReport(undefined);
        } else if (response.status === 204) {
          setWarning(true);
          setError(false);
          setReport(undefined);
          setNotFound(false);
          setLoading(false);
        } else if (response.status === 202) {
          setLoading(true);
          setWarning(false);
          setReport(undefined);
          setError(false);
          setNotFound(false);
          setCode(hashcode);
        }
      })
      .catch((response) => {
        if (response.response.status === 401) {
          updateToken((success, newToken) => {
            if (success) {
              rescan(hashcode, newToken);
            } else {
              navigate("/signin");
            }
          });
        }
      });
  };

  const download = (hashcode, accessToken) => {
    if (!accessToken) {
      setUpgrade(false);
      setPopUp(true);
      setError(false);
      setWarning(false);
      return;
    } else if (user.plan === "FREE") {
      setUpgrade(true);
      setPopUp(true);
      setError(false);
      setWarning(false);
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access}`,
    };
    axios
      .post(
        `${baseURL}/download/`,
        { hashcode: hashcode },
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .then((response) => {
        fileDownload(response.data, report["sha256"]);
      })
      .catch((response) => {
        if (response.response.status === 401) {
          updateToken((success, newToken) => {
            if (success) {
              download(hashcode, newToken);
            } else {
              navigate("/signin");
            }
          });
        } else {
          setErrorMessage("Not supported File aren't available for download");
          setErrorTitle("Not available for download");
          setReport(undefined);
          setWarning(false);
          setError(true);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (loading) {
      let interval = setInterval(() => {
        if (token) {
          fileSearch(hashcode);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [loading]);

  return (
    <>
      {error && (
        <Error
          title={errorTitle !== "" ? errorTitle : "Hashcode not supported"}
          message={
            errorMessage !== ""
              ? errorMessage
              : "Concider using MD5, SHA1, SHA256 hashcodes"
          }
          close={() => {
            setError(false);
            setErrorMessage("");
          }}
        />
      )}
      {warning && (
        <Warning
          title="API calls limit"
          message="Concider sign in for a permium plan for unlimited number of requests"
          close={() => setWarning(false)}
        />
      )}
      {popUp && <PopUp close={() => setPopUp(false)} upgrade={upgrade} />}
      <div className="max-h-screen">
        <div className="flex flex-col w-full min-h-screen">
          <Navbar />
          <div className="grow">
            <Background styling="w-full p-20 grid items-center">
              <h1 className="text-5xl lg:text-6xl text-center text-gray-300">
                Analysis <span className="font-bold text-white">Report</span>
              </h1>
            </Background>
            {notFound && (
              <div className="m-0 grid justify-items-center py-14">
                <h2 className="mb-5 text-2xl text-gray-600">
                  <span className="font-bold text-nucleon-600">404</span> File
                  not Found
                </h2>

                <img className="h-52" src={notFound404} alt="not found" />

                <Link
                  to="/"
                  className="flex items-center text-2xl py-2 px-6 mt-5 "
                >
                  <span className="text-nucleon-600 font-bold mx-1.5">
                    Upload your file
                  </span>
                  to get the analysis result
                </Link>
              </div>
            )}
            {report && (
              <div className="grid justify-items-center px-10 lg:px-5 pb-5 pt-10 bg-white snap-end border-t-2 border-t-gray-200">
                <div className="sm:flex w-full gap-5 max-w-7xl justify-between">
                  <div className="grid items-center py-4">
                    <Link
                      to="/"
                      className="text-center bg-nucleon-800/90 hover:bg-nucleon-800 py-2 px-5 text-white font-semibold rounded-md"
                    >
                      Scan another file
                    </Link>
                  </div>
                  <div className="grid sm:flex items-center py-4">
                    <div className="grid sm:flex gap-2.5">
                      <button
                        onClick={() => rescan(hashcode, token)}
                        className="grid justify-center text-center bg-nucleon-400 hover:bg-nucleon-500 focus:bg-nucleon-500 py-2 px-5 text-white font-semibold rounded-md"
                      >
                        <div className="flex items-center w-fit">
                          <MdLoop className="text-xl mr-2.5" /> Rescan
                        </div>
                      </button>
                      <button
                        onClick={() => download(hashcode, token)}
                        className="grid justify-center selection:text-center bg-nucleon-400 hover:bg-nucleon-500 focus:bg-nucleon-500 hover: py-2 px-5 text-white font-semibold rounded-md"
                      >
                        <div className="flex items-center">
                          <MdFileDownload className="text-xl mr-2.5" /> Download
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full gap-10 max-w-7xl border mb-5">
                  <div
                    className="md:grid"
                    style={{ gridTemplateColumns: "auto 1fr" }}
                  >
                    {report.supported ? (
                      !loading ? (
                        <div className="md:border-r grid justify-items-center items-center p-10 gap-10">
                          <div className="grid items-center gap-5">
                            <div>
                              <CircularProgressbar
                                className="h-28"
                                styles={buildStyles({
                                  textColor: color(percentage(report.score)),
                                  pathColor: color(percentage(report.score)),
                                  textSize: "16px",
                                  trailColor: "#f8fafc",
                                })}
                                value={percentage(report.score)}
                                text={`${percentage(report.score)}%`}
                              />
                            </div>

                            <div className="div text-center">
                              <p className="text-sm">Label</p>
                              <h3
                                className="text-xl font-semibold"
                                style={{
                                  color: color(percentage(report.score)),
                                }}
                              >
                                {report.label.charAt(0).toUpperCase() +
                                  report.label.slice(1)}
                              </h3>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="md:border-r grid justify-items-center items-center p-10 gap-10">
                          <div className="grid items-center gap-5">
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="w-28 h-28 mr-2 text-gray-200 animate-spin fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                            <div className="div text-center">
                              <h3 className="text-sm font-semibold w-28">
                                File is being analyzed
                              </h3>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="md:border-r grid justify-items-center items-center p-10 gap-10">
                        <div className="items-center gap-10 flex w-fit">
                          <h3 className="text-xl font-bold text-nucleon-800">
                            No score
                          </h3>
                        </div>
                      </div>
                    )}
                    <dl className="p-5 sm:p-0 sm:divide-y sm:divide-gray-200">
                      {["md5", "sha1", "sha256"].map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6"
                          >
                            <dt className="text-sm font-medium text-gray-500">
                              {item.toUpperCase()}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5 hash truncate w-[70vw]">
                              {report[item]}
                            </dd>
                          </div>
                        );
                      })}
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Size
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                          {formatBytes(report["size"])}
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5 hash truncate">
                          {!Array.isArray(report["name"]) ? (
                            report["name"]
                          ) : report["name"].length > 1 ? (
                            <ul>
                              {report["name"].map((item, index) => {
                                {
                                  console.log(item);
                                }
                                {
                                  if (item)
                                    return (
                                      <li className="py-0.5" key={index}>
                                        {item}
                                      </li>
                                    );
                                }
                              })}
                            </ul>
                          ) : (
                            report["name"][0]
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      {report["parent_hash"] &&
                        (console.log(report["parent_hash"]) || (
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Parent hash
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5 hash">
                              {!Array.isArray(report["parent_hash"]) ? (
                                report["parent_hash"]
                              ) : report["parent_hash"].length > 1 ? (
                                <ul>
                                  {console.log(report["parent_hash"])}
                                  {report["parent_hash"].map((item, index) => {
                                    {
                                      if (item)
                                        return (
                                          <li className="py-0.5" key={index}>
                                            {item}
                                          </li>
                                        );
                                    }
                                  })}
                                </ul>
                              ) : (
                                report["parent_hash"][0]
                              )}
                            </dd>
                          </div>
                        ))}

                      {[
                        ["type", "Type"],
                        ["magic", "Magic"],
                        ["trid", "Trid"],
                        ["encoding", "Encoding"],
                      ].map((item, index) => {
                        let [field, title] = item;
                        if (report[field])
                          return (
                            <div
                              key={index}
                              className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {title}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                {field !== "size" && report[field]}{" "}
                                {field === "size" && formatBytes(report[field])}
                              </dd>
                            </div>
                          );
                      })}
                      {report["insert_date"] && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Submission date
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                            {date(new Date(report["insert_date"] * 1000))}
                          </dd>
                        </div>
                      )}
                      {report["last_scan"] && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Last scan
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                            {date(new Date(report["last_scan"] * 1000))}
                          </dd>
                        </div>
                      )}
                      {report["score_by_model"] && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Score by model
                          </dt>
                          <dd
                            className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5 font-bold"
                            style={{
                              color: color(
                                percentage(report["score_by_model"])
                              ),
                            }}
                          >
                            {Math.round(report["score_by_model"] * 100, 2)} %
                          </dd>
                        </div>
                      )}
                      {report["nested"] && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Nested Objects
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                            {report["nested"].length}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </div>

                {report["nested"] &&
                  report["nested"].some((row) =>
                    ["URL", "IP", "DOMAIN"].includes(row.category)
                  ) && (
                    <>
                      {["URL", "IP", "DOMAIN"].map((category) => {
                        return report["nested"].some(
                          (row) => row.category === category
                        ) ? (
                          <ReportCategory
                            key={category}
                            category={category}
                            report={report}
                          />
                        ) : null;
                      })}
                    </>
                  )}

                {report["nested"] &&
                  report["nested"].some(
                    (row) => !["URL", "IP", "DOMAIN"].includes(row.category)
                  ) && (
                    <div className="py-4 sm:py-5 w-full max-w-7xl overflow-scroll">
                      <dt className="text-sm font-medium text-gray-500">
                        Nested Objects
                      </dt>
                      <table className="overflow-scroll divide-y divide-gray-300 mt-2.5 max-w-7xl w-full border">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Score
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Size
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Sha256
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {report["nested"]
                            .filter(
                              (row) =>
                                !["URL", "IP", "DOMAIN"].includes(row.category)
                            )
                            .map((field, key) => (
                              <tr key={key}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {field.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {field.type}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {field.complete ? (
                                    field.score == "NaN" ? (
                                      "-"
                                    ) : (
                                      <span
                                        className="font-bold"
                                        style={{
                                          color: color(percentage(field.score)),
                                        }}
                                      >
                                        {Math.round(field.score * 100, 2) + "%"}{" "}
                                      </span>
                                    )
                                  ) : !field.supported ? (
                                    "-"
                                  ) : (
                                    <div role="status">
                                      <svg
                                        aria-hidden="true"
                                        className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentFill"
                                        />
                                      </svg>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {formatBytes(field.size)}
                                </td>

                                <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-blue-500 hash">
                                  <Link to={`/report/${field.sha256}`}>
                                    {field.sha256.trim()}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Report;
